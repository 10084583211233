/* You can add global styles to this file, and also import other style files */
.material-icons
{
    display: none!important;
} 
    
.footer-info
{
    display: none!important;
}

.image-gallery-2
{
    background: white!important;
}

@import '../node_modules/primeng/resources/themes/nova-accent/theme.css';